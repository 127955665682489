<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tableone">
        <div class="beijing" v-if="nianfen_xianshi_yincang">
            <div class="box">
                <p class="tit_box">盈亏平衡测算</p>
                <p class="con">请选择需要创建目标的时间</p>
                <div class="con_box">
                    选择年份&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-date-picker
                        v-model="nian_sj"
                        type="year"
                        format='yyyy年'
                        placeholder="选择年">
                    </el-date-picker>
                </div>
                <div class="foot">
                    <span @click="xuanze_nianfen_queding">确定</span>
                    <span @click="xuanze_nianfen_quxiao">取消</span>
                </div>
            </div>
        </div>
        <p class="title">{{time}}&nbsp;&nbsp;盈亏平衡测算</p>
        <div class="tit">
            <p class="tit_p1">
                <span @click="nianfen_xianshi_yincang=true">新建</span>
                年份
                <el-select v-model="time" @change='xuanze_nianfen_biao'>
                    <el-option
                        v-for="(i,index) in cunzai_shijian_list"
                        :key="index"
                        :label="i.time"
                        :value="i.time">
                    </el-option>
                </el-select>
            </p>
            <div class="tit_div1">
                <p v-for="(i,index) in yi_erji_list" :key="index" @click="dian_yi_erji(index)">
                    <img :src="index == yierji?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')"  alt="">{{i}}
                </p>
            </div>
            <p class="danwei">
                单位：元
                <!-- <el-switch
                    class="demo"
                    v-model='danwei'
                    @change="danwei_xuanze"
                    active-color="#ff4949"
                    inactive-color="#ff4949"
                    active-text="万元"
                    inactive-text="元">
                </el-switch> -->
            </p>
        </div>
        <div class="laoye-table-head">
            <p class="p2">
                <span @click="dianji_guanbi">关闭</span>
                <span :style="baocun_liang_or_no?'background:#9a86db;border:0.01rem solid #9a86db;color:#fff;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;color:#fff;'" @click="baocun_biao">保存</span>
                <span @click="jin_chutu" :style="chutu?'background:#9a86db;border:0.01rem solid #9a86db;color:#fff;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;color:#fff;'">出图</span>
            </p>
        </div>
        <table class="laoye-tbody" border="1">
            <tr class="laoye-tit">
                <td>项目级别</td>
                <td>项目</td>
                <td>占销售额比率%</td>
                <td>金额</td>
                <td v-if="yierji==1">操作</td>
            </tr>
            <tr>
                <td>一级</td>
                <td>固定经费</td>
                <td class="wuyong"></td>
                <td :class="guding_list==''||yierji==0?'':'zidong'">
                    <input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" v-if="guding_list==''||yierji==0" style='background: #fff;' placeholder="请填写" type="number" v-model="modalb.guding_jine" @keyup="zong_guding_jine">
                    {{guding_list==''||yierji==0?'':modalb.guding_jine}}
                </td>
                <td v-if="yierji==1">
                    <div class="caozuo" @click="dianji_xinzeng('2','固定经费科目')">
                        +<span>新增</span>
                    </div>
                </td>
            </tr>
            <tr class="er_ji" v-for="(i,index) in guding_list" :key="i.id" >
                <template v-if="yierji==1">
                    <td style="background:#fff;">明细</td>
                    <td>{{i.label}}</td>
                    <td class="wuyong"></td>
                    <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" style='background: #F4F7FD;' placeholder="请填写" type="number" v-model="guding_list[index].jine" @keyup="guding_bianji"></td>
                    <td style="background:#fff;"><span class="shan" @click="shanchu_guding(index)">删除</span></td>
                </template>
            </tr>
            <tr>
                <td>一级</td>
                <td>变动经费</td>
                <td class="zidong">
                    {{modala.biandong_jingfei_baifenbi}}
                    <s>%</s>
                </td>
                <td class="zidong">{{modalb.biandong_jingfei_jine}}</td>
                <td v-if="yierji==1">
                    <!-- <div class="caozuo" @click="dianji_xinzeng('3002','变动费用科目')">
                        +<span>新增</span>
                    </div> -->
                </td>
            </tr>
            <tr>
                <td>一级</td>
                <td>变动费用</td>
                <td :class="biandong_list==''||yierji==0?'':'zidong'">
                    <input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" v-if="biandong_list==''||yierji==0" style='background: #fff;' placeholder="请填写（1-100）数字" type="number" v-model="modala.biandong_baifenbi" @keyup="zong_biandong_baifenbi">
                    {{biandong_list==''||yierji==0?'':modala.biandong_baifenbi}}
                    <s>%</s>
                </td>
                <td class="zidong">{{modalb.biandong_jine}}</td>
                <td v-if="yierji==1">
                    <div class="caozuo" @click="dianji_xinzeng('3002','变动费用科目')">
                        +<span>新增</span>
                    </div>
                </td>
            </tr>
            <tr  class="er_ji" v-for='(i,index) in biandong_list' :key="i.id" >
                <template  v-if="yierji==1">
                    <td style="background:#fff;">明细</td>
                    <td>{{i.label}}</td>
                    <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" style='background: #F4F7FD;' type="number" placeholder="请填写（1-100）数字" v-model="biandong_list[index].baifenbi" @keyup="biandong_bianji(index)"><s>%</s></td>
                    <td class="zidong">{{i.jine}}</td>
                    <td style="background:#fff;">
                        <span class="shan" @click="shanchu_biandong(index)">删除</span>
                    </td>
                </template>
            </tr>
            <tr>
                <td>一级</td>
                <td>成本</td>
                <td :class="chengben_list==''||yierji==0?'':'zidong'">
                    <input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" v-if="chengben_list==''||yierji==0" style='background: #fff;' placeholder="请填写（1-100）数字" type="number" v-model="modala.chengben_baifenbi" @keyup="key2">
                    {{chengben_list==''||yierji==0?'':modala.chengben_baifenbi}}
                    <s>%</s>
                </td>
                <td class="zidong">{{modalb.chengben_jine}}</td>
                <td v-if="yierji==1">
                    <div class="caozuo" @click="dianji_xinzeng('3001','成本科目')">
                        +<span>新增</span>
                    </div>
                </td>
            </tr>
            <tr  class="er_ji" v-for='(i,index) in chengben_list' :key="i.id" >
                <template  v-if="yierji==1">
                    <td style="background:#fff;">明细</td>
                    <td>{{i.label}}</td>
                    <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" style='background: #F4F7FD;' type="number" placeholder="请填写（1-100）数字" v-model="chengben_list[index].baifenbi" @keyup="chengben_bianji(index)"><s>%</s></td>
                    <td class="zidong">{{i.jine}}</td>
                    <td style="background:#fff;">
                        <span class="shan" @click="shanchu_chengben(index)">删除</span>
                    </td>
                </template>
            </tr>
            <tr>
                <td>一级</td>
                <td>税金</td>
                <td :class="shuijin_list==''||yierji==0?'':'zidong'">
                    <input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" v-if="shuijin_list==''||yierji==0" style='background: #fff;' placeholder="请填写（1-100）数字" type="number" v-model="modala.shuijin_baifenbi" @keyup="zong_shuijin_baifenbi">
                    {{shuijin_list==''||yierji==0?'':modala.shuijin_baifenbi}}
                    <s>%</s>
                </td>
                <td class="zidong">{{modalb.shuijin_jine}}</td>
                <td v-if="yierji==1">
                    <!-- <div class="caozuo" @click="dianji_xinzeng('3003','税金科目')">
                        +<span>新增</span>
                    </div> -->
                </td>
            </tr>
            <tr  class="er_ji" v-for='(i,index) in shuijin_list' :key="i.id" >
                <template  v-if="yierji==1">
                    <td style="background:#fff;">明细</td>
                    <td>{{i.label}}</td>
                    <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" style='background: #F4F7FD;' type="number" placeholder="请填写（1-100）数字" v-model="shuijin_list[index].baifenbi" @keyup="shuijin_bianji(shuijin_list[index])"><s>%</s></td>
                    <td class="zidong">{{i.jine}}</td>
                    <td style="background:#fff;">
                        <!-- <span class="shan" @click="shanchu_biandong(index)">删除</span> -->
                    </td>
                </template>
            </tr>
            <tr>
                <td>一级</td>
                <td>盈亏平衡点</td>
                <td class="wuyong"></td>
                <td class="zidong">{{modalb.pingheng_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr> 
                <td>一级</td>
                <td>本年目标销售额增长</td>
                <td class="zidong">{{modala.bennian_xiaoshou_baifenbi}}<s>%</s></td>
                <td class="zidong">{{modalb.bennian_xiaoshou_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>上年销售额</td>
                <td class="wuyong"></td>
                <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" @keyup="key15" type="number" v-model="two_modalb.shangnianxiaoshoue_jine" placeholder="请填写"></td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>  <!-- 海未绑定 -->
                <td>一级</td>
                <td>本年目标销售额</td>
                <td class="wuyong"></td>
                <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" type="number" v-model="two_modalb.cesuanmubiao_jine" placeholder="请填写">
                    <p class="xuanfuzi" v-if="cuowu_text">您设定的目标销售额已低于盈亏平衡点。</p>
                </td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>客单价</td>
                <td class="wuyong"></td>
                <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" @keyup="key7" type="number" v-model="modalb.kedanjia_jine" placeholder="请填写"></td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>人均产值</td>
                <td class="wuyong"></td>
                <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" @keyup="key9" type="number" v-model="modalb.renjunchanzhi_jine" placeholder="请填写"></td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>销售编制人数</td>
                <td class="wuyong"></td>
                <td class="zidong">{{modalb.cesuan_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
            <!-- <tr>
                <td>一级</td>
                <td>测算本年目标增长率</td>
                <td class="zidong">{{modala.zengzhang_baifenbi}}</td>
                <td class="wuyong"></td>
                <td></td>
            </tr> -->
            <!-- <tr  class="er_ji" v-if="yierji==1" >
                <td>二级</td>
                <td>测算目标销售额</td>
                <td class="wuyong"></td>
                <td><input onkeydown="if(event.keyCode==38||event.keyCode==40)return false;" @keyup="key17" type="number" v-model="two_modalb.cesuanmubiao_jine" style='background: #F4F7FD;' placeholder="请填写（1-100）数字"></td>
                <td></td>
            </tr> -->
            <tr>
                <td>一级</td>
                <td>销售毛利</td>
                <td class="zidong">{{modala.maoli_baifenbi}}<s>%</s></td>
                <td class="zidong">{{modalb.maoli_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>边界效益</td>
                <td class="zidong">{{modala.bianjie_baifenbi}}<s>%</s></td>
                <td class="zidong">{{modalb.bianjie_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
            <tr>
                <td>一级</td>
                <td>经营利润</td>
                <td class="zidong">{{modala.jingying_baifenbi}}<s>%</s></td>
                <td class="zidong">{{modalb.jingying_jine}}</td>
                <td v-if="yierji==1"></td>
            </tr>
        </table>
        <div class="xuanze_liebiao" @click="xuanze_xinzeng=false" v-if="xuanze_xinzeng">
            <div @click.stop class="box">
                <p class="xuan_tit">新增{{xinzeng_tit}}</p>
                <p class="box_xuanze">选择：</p>
                <div class="liebiao">
                    <el-tree
                        :data="xinzeng_list"
                        show-checkbox
                        default-expand-all
                        node-key="subject_code"
                        :expand-on-click-node="false"
                        ref='tree'
                        style="background:#D8E6F7;"
                        :default-checked-keys='xuanzhong_gouxuan'
                        >
                        <span class="custom-tree-node" slot-scope="{ node,data}">
                            <span>{{ node.label }}</span>
                            <span v-if="data.children!=undefined"><img src="../../assets/chazi_ok.png" alt=""></span>
                        </span>
                    </el-tree>
                </div>
                <div class="xuan_box_foot">
                    <span @click="xuanze_xinzeng_queding">确定</span>
                    <span @click="xuanze_xinzeng=false">取消</span>
                </div>
            </div>
        </div>
        <tuyi ref="mubiao_tu1"></tuyi> 
    </div>
</template>

<script>
// import {sb} from '../../Js/shangxia'
import tuyi from '../Figures/Figureone'
import { query_as_breakeven, query_ent_subject_list_tree, query_ent_subject_list_detail, insert_as_breakeven, query_ent_subject_list, query_subject_info_list, query_subject_info_list_fixed_variable, query_as_year_list } from '../../api/api.js'
export default {
  name: 'tableone',
  data () {
    return {
      yi_erji_list: [
        '一级科目', '明细科目'
      ],
      yierji: '0', // 一二级的下标
      time: '', // 绑定的时间
      cunzai_shijian: [],
      guding_list: [],
      biandong_list: [],
      chengben_list: [],
      shuijin_list: [],
      modala: { // 一级百分比
        guding_baifenbi: '',
        biandong_baifenbi: '',
        biandong_jingfei_baifenbi: '',
        chengben_baifenbi: '',
        shuijin_baifenbi: '',
        kedanjia_baifenbi: '',
        renjunchanzhi_baifenbi: '',
        guding_baifenbi: '',
        cesuan_baifenbi: '',
        pingheng_baifenbi: '',
        zengzhang_baifenbi: '',
        maoli_baifenbi: '',
        bianjie_baifenbi: '',
        jingying_baifenbi: '',
        bennian_xiaoshou_baifenbi: ''
      },
      modalb: { // 一级金额
        guding_jine: '',
        biandong_jine: '',
        biandong_jingfei_jine: '',
        chengben_jine: '',
        shuijin_jine: '',
        kedanjia_jine: '',
        renjunchanzhi_jine: '',
        cesuan_jine: '',
        pingheng_jine: '',
        zengzhang_jine: '',
        maoli_jine: '',
        bianjie_jine: '',
        jingying_jine: '',
        bennian_xiaoshou_jine: ''
      },
      two_modala: { // 二级百分比
        shuifu_baifenbi: '',
        shuijin_baifenbi: '',
        cesuanmubiao_baifenbi: ''
      },
      two_modalb: { // 二级金额
        shuifu_jine: '',
        shuijin_jine: '',
        shangnianxiaoshoue_jine: '',
        cesuanmubiao_jine: ''
      },
      nian_sj: '', // 刚开始选择的时间
      nianfen_xianshi_yincang: '', // 年份选择显示与隐藏
      cunzai_shijian_list: [], // 存在的时间列表
      tianjia_xuanze: '',
      shujuku_cunzai_shijian: '',
      baocun_liang_or_no: false, // 亮与不亮
      xuanze_xinzeng: false, // 选择新增
      xinzeng_tit: '',
      xinzeng_list: [],
      xuanze_xinzeng_list: [],
      danwei: false,
      cuowu_text: false, // 提示文字   盈亏平衡点大于目标销售额
      chutu: false, // 出图变亮
      quan: '',
      chuxian: '',
      xuanzhong_gouxuan: []
    }
  },
  created () {
    this.jichu()
  },
  components: { tuyi },
  mounted () {
    // console.log(this.$store.state.biaoyi_shijian)   //暂时用不到
    // setTimeout(() => {
    //     sb()  
    // }, 1)
    this.$refs.mubiao_tu1.biaoge_dakai()
  },
  watch: {
    // 盈亏平衡点金额
    modala: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_chengben_jine()
        this.zidong_shuijin_kemu_jine()
        this.zidong_biandong_jingfei_jine()
        this.zidong_chengben_kemu_jine()
        this.zidong_bennian_mubiao_zengzhang_baifenbi()
        this.zidong_maoli_jine()
        this.zidong_bianjie_baifenbi()
        this.zidong_shuijin_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
        this.panduan_baocun_biao()
        this.zidong_biandong_jingfei_baifenbi_or_jine()
      },
      deep: true
    },
    modalb: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_biandong_jingfei_jine()
        this.zidong_chengben_jine()
        this.zidong_shuijin_kemu_jine()
        this.zidong_chengben_kemu_jine()
        this.zidong_bennian_mubiao_zengzhang_baifenbi()
        this.zidong_maoli_jine()
        this.zidong_bianjie_baifenbi()
        this.zidong_jingying_baifenbi()
        this.cunchu()
        this.panduan_baocun_biao()
        this.cuowu()
        this.zidong_biandong_jingfei_baifenbi_or_jine()
      },
      deep: true
    },
    two_modala: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_bennian_mubiao_zengzhang_baifenbi()
        this.zidong_maoli_jine()
        this.zidong_bianjie_baifenbi()
        this.zidong_biandong_jingfei_jine()
        this.zidong_shuijin_baifenbi()
        // this.zidong_shuifu_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
        this.panduan_baocun_biao()
      },
      deep: true
    },
    two_modalb: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_bennian_mubiao_zengzhang_baifenbi()
        this.zidong_maoli_jine()
        this.zidong_bianjie_baifenbi()
        this.zidong_bennian_mubiao_xiaoshou_zhengzange()
        this.zidong_shuijin_jine()
        this.zidong_biandong_kemu_jine()
        // this.zidong_shuifu_jine()
        this.zidong_biandong_jingfei_jine()
        this.zidong_shuijin_kemu_jine()
        this.zidong_chengben_kemu_jine()
        this.zidong_chengben_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
        this.panduan_baocun_biao()
        this.cuowu()
      },
      deep: true
    },
    guding_list: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_guding_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
      },
      deep: true
    },
    biandong_list: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_biandong_baifenbi()
        this.zidong_jingying_baifenbi()
        this.cunchu()
      },
      deep: true
    },
    chengben_list: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_chengben_baifenbi()
        this.zidong_chengben_kemu_jine()
        this.zidong_chengben_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
      },
      deep: true
    },
    shuijin_list: {
      handler (newValue, oldValue) {
        this.zidong_pingheng_jine()
        this.zidong_shuijin_baifenbi()
        this.zidong_shuijin_kemu_jine()
        this.zidong_jingying_baifenbi()
        this.cunchu()
      },
      deep: true
    },
    time () {
      console.log(this.time)
    }
  },
  methods: {
    xuanze_nianfen_biao () {
      this.cunzai_shijian_list.forEach(item => {
        if(item.time == new Date().getFullYear()) {
          this.time = item
        }
      })
      for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
        if (this.cunzai_shijian_list[i].time == this.time) {
          if (this.cunzai_shijian_list[i].modala == undefined) {
            query_as_breakeven({
              data: {
                ent_id: this.$ent_id(),
                year: this.time.slice(0, 4)
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10229) {
                const list = JSON.parse(res.data.body.data)
                console.log(list)
                this.nianfen_xianshi_yincang = false
                for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                  console.log(this.cunzai_shijian_list[i].time.slice(0, 4))
                  if (this.cunzai_shijian_list[i].time.slice(0, 4) == this.time.slice(0, 4)) {
                    const zhi = this.cunzai_shijian_list
                    // zhi[i]['modala']={
                    //     biandong_baifenbi:list.variable_funds_rate,
                    //     chengben_baifenbi:list.cost_rate,
                    //     shuijin_baifenbi:list.amt_tax_rate,
                    //     maoli_baifenbi:list.profit_gross_rate,
                    //     bianjie_baifenbi:list.profit_margin_rate,
                    //     jingying_baifenbi:list.profit_rate,
                    // }
                    this.$set(zhi[i], 'modala', {
                      biandong_baifenbi: list.variable_funds_rate,
                      chengben_baifenbi: list.cost_rate,
                      shuijin_baifenbi: list.amt_tax_rate,
                      maoli_baifenbi: list.profit_gross_rate,
                      bianjie_baifenbi: list.profit_margin_rate,
                      jingying_baifenbi: list.profit_rate
                    })
                    // zhi[i]['modalb']={
                    //     guding_jine:list.fixed_funds+'',
                    //     biandong_jine:list.variable_funds,
                    //     chengben_jine:list.cost,
                    //     shuijin_jine:list.amt_tax,
                    //     kedanjia_jine:list.pct,
                    //     renjunchanzhi_jine:list.per_output_value,
                    //     bennian_xiaoshou_jine:list.amt_growth_aim_est,
                    //     maoli_jine:list.profit_gross,
                    //     bianjie_jine:list.profit_margin,
                    //     jingying_jine:list.profit,
                    // }
                    this.$set(zhi[i], 'modalb', {
                      guding_jine: list.fixed_funds + '',
                      biandong_jine: list.variable_funds,
                      chengben_jine: list.cost,
                      shuijin_jine: list.amt_tax,
                      kedanjia_jine: list.pct,
                      renjunchanzhi_jine: list.per_output_value,
                      bennian_xiaoshou_jine: list.amt_growth_aim_est,
                      maoli_jine: list.profit_gross,
                      bianjie_jine: list.profit_margin,
                      jingying_jine: list.profit
                    })
                    // zhi[i]['two_modala']={
                    //     cesuanmubiao_baifenbi:''
                    // }
                    this.$set(zhi[i], 'two_modala', {
                      cesuanmubiao_baifenbi: ''
                    })
                    // zhi[i]['two_modalb']={
                    //     shangnianxiaoshoue_jine:list.amt_sale_ly,
                    //     cesuanmubiao_jine:list.amt_sale_aim_est
                    // }
                    this.$set(zhi[i], 'two_modalb', {
                      shangnianxiaoshoue_jine: list.amt_sale_ly,
                      cesuanmubiao_jine: list.amt_sale_aim_est
                    })
                    // zhi[i]['diyiwei']='1'
                    this.$set(zhi[i], 'diyiwei', '1')
                    this.modala = zhi[i].modala
                    this.modalb = zhi[i].modalb
                    this.two_modala = zhi[i].two_modala
                    this.two_modalb = zhi[i].two_modalb
                    // if(date[i].yierji==1){
                    //     this.guding_list=date[i].guding_list
                    //     this.biandong_list=date[i].biandong_list
                    //     this.chengben_list=date[i].chengben_list
                    //     this.shuijin_list=date[i].shuijin_list
                    // }
                    if (list.cost_list != undefined || list.fixed_list != undefined || list.tax_list != undefined || variable_list != undefined) {
                      // zhi[i]['yierji']='1'
                      this.$set(zhi[i], 'yierji', '1')
                      if (list.cost_list != undefined) {
                        // zhi[i]['chengben_list']=JSON.parse(list.cost_list)
                        this.$set(zhi[i], 'chengben_list', JSON.parse(list.cost_list))
                        for (let a = 0; a < zhi[i].chengben_list.length; a++) {
                          // zhi[i].chengben_list[a]['jine']=JSON.parse(list.cost_list)[a].amt
                          // zhi[i].chengben_list[a]['baifenbi']=JSON.parse(list.cost_list)[a].rate
                          this.$set(zhi[i].chengben_list[a], 'jine', JSON.parse(list.cost_list)[a].amt)
                          this.$set(zhi[i].chengben_list[a], 'baifenbi', JSON.parse(list.cost_list)[a].rate)
                        }
                        this.chengben_list = zhi[i].chengben_list
                      }
                      if (list.fixed_list != undefined) {
                        // zhi[i]['guding_list']=JSON.parse(list.fixed_list)
                        this.$set(zhi[i], 'guding_list', JSON.parse(list.fixed_list))
                        for (let a = 0; a < zhi[i].guding_list.length; a++) {
                          // zhi[i].guding_list[a]['jine']=JSON.parse(list.fixed_list)[a].amt
                          this.$set(zhi[i].guding_list[a], 'jine', JSON.parse(list.fixed_list)[a].amt)
                        }
                        this.guding_list = zhi[i].guding_list
                      }
                      if (list.tax_list != undefined) {
                        // zhi[i]['shuijin_list']=JSON.parse(list.tax_list)
                        this.$set(zhi[i], 'shuijin_list', JSON.parse(list.tax_list))
                        for (let a = 0; a < zhi[i].shuijin_list.length; a++) {
                          // zhi[i].shuijin_list[a]['jine']=JSON.parse(list.tax_list)[a].amt
                          this.$set(zhi[i].shuijin_list[a], 'jine', JSON.parse(list.tax_list)[a].amt)
                          // zhi[i].shuijin_list[a]['baifenbi']=JSON.parse(list.tax_list)[a].rate
                          this.$set(zhi[i].shuijin_list[a], 'baifenbi', JSON.parse(list.tax_list)[a].rate)
                        }
                        this.shuijin_list = zhi[i].shuijin_list
                      }
                      if (list.variable_list != undefined) {
                        // zhi[i]['biandong_list']=JSON.parse(list.variable_list)
                        this.$set(zhi[i], 'biandong_list', JSON.parse(list.variable_list))
                        for (let a = 0; a < zhi[i].biandong_list.length; a++) {
                          // zhi[i].biandong_list[a]['jine']=JSON.parse(list.variable_list)[a].amt
                          this.$set(zhi[i].biandong_list[a], 'jine', JSON.parse(list.variable_list)[a].amt)
                          // zhi[i].biandong_list[a]['baifenbi']=JSON.parse(list.variable_list)[a].rate
                          this.$set(zhi[i].biandong_list[a], 'baifenbi', JSON.parse(list.variable_list)[a].rate)
                        }
                        this.biandong_list = zhi[i].biandong_list
                      }
                      this.yierji = '1'
                    } else {
                      const zhi = this.cunzai_shijian_list
                      // zhi[i]['yierji']='0'
                      this.$set(zhi[i], 'yierji', '0')
                      this.yierji = '0'
                      this.guding_list = ''
                      this.biandong_list = ''
                      this.chengben_list = ''
                      this.shuijin_list = ''
                      // zhi[i]['guding_list']=''
                      // zhi[i]['biandong_list']=''
                      // zhi[i]['chengben_list']=''
                      // zhi[i]['shuijin_list']=''
                      this.$set(zhi[i], 'guding_list', '')
                      this.$set(zhi[i], 'biandong_list', '')
                      this.$set(zhi[i], 'chengben_list', '')
                      this.$set(zhi[i], 'shuijin_list', '')
                    }
                  }
                }
                console.log(this.cunzai_shijian_list)
              } else if (res.data.code == 10230) {}
            })
          } else {
            if(this.cunzai_shijian_list[i].time.slice(0, 4) == this.time.slice(0, 4)){
              this.time = this.cunzai_shijian_list[i].time
              this.yierji = this.cunzai_shijian_list[i].yierji
              this.modala = this.cunzai_shijian_list[i].modala
              this.modalb = this.cunzai_shijian_list[i].modalb
              this.two_modala = this.cunzai_shijian_list[i].two_modala
              this.two_modalb = this.cunzai_shijian_list[i].two_modalb
              if (this.cunzai_shijian_list[i].yierji == 1) {
                this.guding_list = this.cunzai_shijian_list[i].guding_list
                this.biandong_list = this.cunzai_shijian_list[i].biandong_list
                this.chengben_list = this.cunzai_shijian_list[i].chengben_list
                this.shuijin_list = this.cunzai_shijian_list[i].shuijin_list
              }
            }
          }
        }
      }
    },
    //   基础
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const data = JSON.parse(res.data.body.data)
          console.log(data)
          this.shujuku_cunzai_shijian = data
          if (data.length == 0) {
            // this.chaxun()
            this.nianfen_xianshi_yincang = true
          } else {
            this.nianfen_xianshi_yincang = false
            this.shujuku_cunzai_shijian.forEach(item => {
              if(item == new Date().getFullYear()) {
                this.time = item + '年'
              }
            })
            
            // if (localStorage.getItem("biaoyi_shijian") != null&&localStorage.getItem("biaoyi_shijian") != undefined&&localStorage.getItem("biaoyi_shijian")!= ''){
            //     let date=JSON.parse(localStorage.getItem("biaoyi_shijian"))
            //     for(let i=0;i<date.length;i++){
            //         if(date[i].time==data[data.length-1]+'年'){
            //             this.time=date[i].time
            //             this.yierji=date[i].yierji
            //             this.modala=date[i].modala
            //             this.modalb=date[i].modalb
            //             this.two_modala=date[i].two_modala
            //             this.two_modalb=date[i].two_modalb
            //             this.cunzai_shijian_list=date
            //             if(date[i].yierji==1){
            //                 this.guding_list=date[i].guding_list
            //                 this.biandong_list=date[i].biandong_list
            //                 this.chengben_list=date[i].chengben_list
            //                 this.shuijin_list=date[i].shuijin_list
            //             }
            //         }
            //     }
            // }else{
            query_as_breakeven({
              data: {
                ent_id: this.$ent_id(),
                year: this.time.slice(0, 4)
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10229) {
                const list = JSON.parse(res.data.body.data)
                console.log(list)
                for (let i = 0; i < data.length; i++) {
                  this.cunzai_shijian_list.push({
                    time: data[i] + '年'
                  })
                }
                for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                  console.log(this.cunzai_shijian_list[i].time.slice(0, 4))
                  if (this.cunzai_shijian_list[i].time.slice(0, 4) == this.time.slice(0, 4)) {
                    const zhi = this.cunzai_shijian_list
                    console.log(zhi)
                    // zhi[i]['modala']={
                    //     biandong_baifenbi:list.variable_funds_rate,
                    //     chengben_baifenbi:list.cost_rate,
                    //     shuijin_baifenbi:list.amt_tax_rate,
                    //     maoli_baifenbi:list.profit_gross_rate,
                    //     bianjie_baifenbi:list.profit_margin_rate,
                    //     jingying_baifenbi:list.profit_rate,
                    // }
                    this.$set(zhi[i], 'modala', {
                      biandong_baifenbi: list.variable_funds_rate,
                      chengben_baifenbi: list.cost_rate,
                      shuijin_baifenbi: list.amt_tax_rate,
                      maoli_baifenbi: list.profit_gross_rate,
                      bianjie_baifenbi: list.profit_margin_rate,
                      jingying_baifenbi: list.profit_rate
                    })
                    // zhi[i]['modalb']={
                    //     guding_jine:list.fixed_funds+'',
                    //     biandong_jine:list.variable_funds,
                    //     chengben_jine:list.cost,
                    //     shuijin_jine:list.amt_tax,
                    //     kedanjia_jine:list.pct,
                    //     renjunchanzhi_jine:list.per_output_value,
                    //     bennian_xiaoshou_jine:list.amt_growth_aim_est,
                    //     maoli_jine:list.profit_gross,
                    //     bianjie_jine:list.profit_margin,
                    //     jingying_jine:list.profit,
                    // }
                    this.$set(zhi[i], 'modalb', {
                      guding_jine: list.fixed_funds + '',
                      biandong_jine: list.variable_funds,
                      chengben_jine: list.cost,
                      shuijin_jine: list.amt_tax,
                      kedanjia_jine: list.pct,
                      renjunchanzhi_jine: list.per_output_value,
                      bennian_xiaoshou_jine: list.amt_growth_aim_est,
                      maoli_jine: list.profit_gross,
                      bianjie_jine: list.profit_margin,
                      jingying_jine: list.profit
                    })
                    // zhi[i]['two_modala']={
                    //     cesuanmubiao_baifenbi:''
                    // }
                    this.$set(zhi[i], 'two_modala', {
                      cesuanmubiao_baifenbi: ''
                    })
                    // zhi[i]['two_modalb']={
                    //     shangnianxiaoshoue_jine:list.amt_sale_ly,
                    //     cesuanmubiao_jine:list.amt_sale_aim_est
                    // }
                    this.$set(zhi[i], 'two_modalb', {
                      shangnianxiaoshoue_jine: list.amt_sale_ly,
                      cesuanmubiao_jine: list.amt_sale_aim_est
                    })
                    // zhi[i]['diyiwei']='1'
                    this.$set(zhi[i], 'diyiwei', '1')
                    this.modala = zhi[i].modala
                    this.modalb = zhi[i].modalb
                    this.two_modala = zhi[i].two_modala
                    this.two_modalb = zhi[i].two_modalb
                    // if(date[i].yierji==1){
                    //     this.guding_list=date[i].guding_list
                    //     this.biandong_list=date[i].biandong_list
                    //     this.chengben_list=date[i].chengben_list
                    //     this.shuijin_list=date[i].shuijin_list
                    // }
                    if (list.cost_list != undefined || list.fixed_list != undefined || list.tax_list != undefined || list.variable_list != undefined) {
                      // zhi[i]['yierji']='1'
                      this.$set(zhi[i], 'yierji', '1')
                      if (list.cost_list != undefined) {
                        // zhi[i]['chengben_list']=JSON.parse(list.cost_list)
                        this.$set(zhi[i], 'chengben_list', JSON.parse(list.cost_list))
                        for (let a = 0; a < zhi[i].chengben_list.length; a++) {
                          // zhi[i].chengben_list[a]['jine']=JSON.parse(list.cost_list)[a].amt
                          this.$set(zhi[i].chengben_list[a], 'jine', JSON.parse(list.cost_list)[a].amt)
                          this.$set(zhi[i].chengben_list[a], 'baifenbi', JSON.parse(list.cost_list)[a].rate)
                          // zhi[i].chengben_list[a]['baifenbi']=JSON.parse(list.cost_list)[a].rate
                        }
                        this.chengben_list = zhi[i].chengben_list
                      }
                      if (list.fixed_list != undefined) {
                        // zhi[i]['guding_list']=JSON.parse(list.fixed_list)
                        console.log(JSON.parse(list.fixed_list))
                        this.$set(zhi[i], 'guding_list', JSON.parse(list.fixed_list))
                        for (let a = 0; a < zhi[i].guding_list.length; a++) {
                          // zhi[i].guding_list[a]['jine']=JSON.parse(list.fixed_list)[a].amt
                          this.$set(zhi[i].guding_list[a], 'jine', JSON.parse(list.fixed_list)[a].amt)
                        }
                        this.guding_list = zhi[i].guding_list
                      }
                      if (list.tax_list != undefined) {
                        // zhi[i]['shuijin_list']=JSON.parse(list.tax_list)
                        this.$set(zhi[i], 'shuijin_list', JSON.parse(list.tax_list))
                        for (let a = 0; a < zhi[i].shuijin_list.length; a++) {
                          // zhi[i].shuijin_list[a]['jine']=JSON.parse(list.tax_list)[a].amt
                          this.$set(zhi[i].shuijin_list[a], 'jine', JSON.parse(list.tax_list)[a].amt)
                          // zhi[i].shuijin_list[a]['baifenbi']=JSON.parse(list.tax_list)[a].rate
                          this.$set(zhi[i].shuijin_list[a], 'baifenbi', JSON.parse(list.tax_list)[a].rate)
                        }
                        this.shuijin_list = zhi[i].shuijin_list
                      }
                      if (list.variable_list != undefined) {
                        // zhi[i]['biandong_list']=JSON.parse(list.variable_list)
                        this.$set(zhi[i], 'biandong_list', JSON.parse(list.variable_list))
                        for (let a = 0; a < zhi[i].biandong_list.length; a++) {
                          // zhi[i].biandong_list[a]['jine']=JSON.parse(list.variable_list)[a].amt
                          this.$set(zhi[i].biandong_list[a], 'jine', JSON.parse(list.variable_list)[a].amt)
                          // zhi[i].biandong_list[a]['baifenbi']=JSON.parse(list.variable_list)[a].rate
                          this.$set(zhi[i].biandong_list[a], 'baifenbi', JSON.parse(list.variable_list)[a].rate)
                        }
                        this.biandong_list = zhi[i].biandong_list
                      }
                      this.yierji = '0'
                    } else {
                      const zhi = this.cunzai_shijian_list
                      // zhi[i]['yierji']='0'
                      this.$set(zhi[i], 'yierji', '0')
                      this.yierji = '0'
                      this.guding_list = ''
                      this.biandong_list = ''
                      this.chengben_list = ''
                      this.shuijin_list = ''
                      // zhi[i]['guding_list']=''
                      // zhi[i]['biandong_list']=''
                      // zhi[i]['chengben_list']=''
                      // zhi[i]['shuijin_list']=''
                      this.$set(zhi[i], 'guding_list', '')
                      this.$set(zhi[i], 'biandong_list', '')
                      this.$set(zhi[i], 'chengben_list', '')
                      this.$set(zhi[i], 'shuijin_list', '')
                    }
                  }
                }
                console.log(this.cunzai_shijian_list)
              } else if (res.data.code == 10230) {}
            })
            // }
          }
        } else if (res.data.code == 10238) {}
      })
      this.chaxun_shifou_chutu()
      // this.guding()
    },
    // 本地是否有时间戳
    chaxun () {
      if (localStorage.getItem('biaoyi_shijian') != null && localStorage.getItem('biaoyi_shijian') != undefined && localStorage.getItem('biaoyi_shijian') != '') {
        // this.left_index = JSON.parse(sessionStorage.getItem("pc_mlbb_mubiao_biao"));
        this.nianfen_xianshi_yincang = false
        const date = JSON.parse(localStorage.getItem('biaoyi_shijian'))
        for (let i = 0; i < date.length; i++) {
          if (date[i].diyiwei == 1) {
            this.time = date[i].time
            this.yierji = date[i].yierji
            this.modala = date[i].modala
            this.modalb = date[i].modalb
            this.two_modala = date[i].two_modala
            this.two_modalb = date[i].two_modalb
            this.cunzai_shijian_list = date
            if (date[i].yierji == 1) {
              this.guding_list = date[i].guding_list
              this.biandong_list = date[i].biandong_list
              this.chengben_list = date[i].chengben_list
              this.shuijin_list = date[i].shuijin_list
            }
          }
        }
      } else {
        this.nianfen_xianshi_yincang = true
      }
    },
    // 公用存储
    cunchu () {
      if (this.cunzai_shijian_list != '') {
        for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
          if (this.cunzai_shijian_list[i].time == this.time) {
            this.cunzai_shijian_list[i].modala = this.modala
            this.cunzai_shijian_list[i].modalb = this.modalb
            this.cunzai_shijian_list[i].two_modala = this.two_modala
            this.cunzai_shijian_list[i].two_modalb = this.two_modalb
            this.cunzai_shijian_list[i].guding_list = this.guding_list
            this.cunzai_shijian_list[i].biandong_list = this.biandong_list
            this.cunzai_shijian_list[i].chengben_list = this.chengben_list
            this.cunzai_shijian_list[i].shuijin_list = this.shuijin_list
            this.cunzai_shijian_list[i].yierji = this.yierji
            this.cunzai_shijian_list[i].diyiwei = '1'
          } else {
            this.cunzai_shijian_list[i].diyiwei = '0'
          }
        }
        localStorage.setItem('biaoyi_shijian', JSON.stringify(this.cunzai_shijian_list))
      } else {
        this.cunzai_shijian_list.push({
          time: this.time,
          yierji: '0',
          diyiwei: '1',
          modala: {
            guding_baifenbi: '',
            biandong_baifenbi: '',
            chengben_baifenbi: '',
            shuijin_baifenbi: '',
            kedanjia_baifenbi: '',
            renjunchanzhi_baifenbi: '',
            guding_baifenbi: '',
            cesuan_baifenbi: '',
            pingheng_baifenbi: '',
            zengzhang_baifenbi: '',
            maoli_baifenbi: '',
            bianjie_baifenbi: '',
            jingying_baifenbi: '',
            bennian_xiaoshou_baifenbi: ''
          },
          modalb: {
            guding_jine: '',
            biandong_jine: '',
            chengben_jine: '',
            shuijin_jine: '',
            kedanjia_jine: '',
            renjunchanzhi_jine: '',
            cesuan_jine: '',
            pingheng_jine: '',
            zengzhang_jine: '',
            maoli_jine: '',
            bianjie_jine: '',
            jingying_jine: '',
            bennian_xiaoshou_jine: ''
          }
        })
        localStorage.setItem('biaoyi_shijian', JSON.stringify(this.cunzai_shijian_list))
      }
    },
    //   点一二级标志
    dian_yi_erji (index) {
      this.yierji = index
      if (index == 1) {
        if (this.shuijin_list.length == 0) {
          this.shuijin_list.push({
            jine: '',
            baifenbi: '',
            label: '增值税及附加',
            subject_code: '3003001'
          })
        }
        if (this.guding_list.length != 0 || this.biandong_list.length != 0 || this.chengben_list.length != 0 || this.shuijin_list.length != 0) {
          this.zidong_guding_jine()
          this.zidong_biandong_baifenbi()
          this.zidong_shuijin_baifenbi()
          this.zidong_pingheng_jine()
          this.zidong_chengben_jine()
          this.zidong_chengben_baifenbi()
          this.zidong_chengben_kemu_jine()
          this.zidong_shuijin_kemu_jine()
          // this.zidong_shuifu_jine()
          // this.zidong_shuijin_fujia_jine()
          this.zidong_shuijin_jine()
          this.zidong_biandong_kemu_jine()
          this.zidong_biandong_jingfei_jine()
          this.zidong_bianzhi_renshu_jine()
          this.zidong_bennian_mubiao_zengzhang_baifenbi()
          this.zidong_bennian_mubiao_xiaoshou_zhengzange()
          this.zidong_maoli_baifenbi()
          this.zidong_maoli_jine()
          this.zidong_bianjie_baifenbi()
          this.zidong_jingying_baifenbi()
          this.cunchu()
        } else {
          query_ent_subject_list_detail({
            data: {
              ent_id: this.$ent_id(),
              subject_prop: '2'
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10241) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'jine', '')
              }
              this.guding_list = date
            } else if (res.data.code == 10242) {}
          })
          query_ent_subject_list_detail({
            data: {
              ent_id: this.$ent_id(),
              subject_prop: '3'
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10241) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'baifenbi', '')
              }
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'jine', '')
              }
              this.biandong_list = date
            } else if (res.data.code == 10242) {}
          })
          query_ent_subject_list_detail({
            data: {
              ent_id: this.$ent_id(),
              subject_prop: '4'
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10241) {
              const date = JSON.parse(res.data.body.data)
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'baifenbi', '')
              }
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'jine', '')
              }
              console.log(date)
              this.chengben_list = date
            } else if (res.data.code == 10242) {}
          })
          query_ent_subject_list_detail({
            data: {
              ent_id: this.$ent_id(),
              subject_prop: '5'
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10241) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'baifenbi', '')
              }
              for (let i = 0; i < date.length; i++) {
                this.$set(date[i], 'jine', '')
              }
              this.shuijin_list = date
            } else if (res.data.code == 10242) {}
          })
        }
      } else {
        if (!isNaN(Number(this.modalb.guding_jine))) {
        } else {
          this.modalb.guding_jine = Number(this.modalb.guding_jine.replace(/,/g, ''))
        }
      }
      this.cunchu()
    },
    // 固定变动经费接口
    guding () {
      query_subject_info_list_fixed_variable({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10213) {
          const guding = JSON.parse(res.data.body.fixed_list)
          const biandong = JSON.parse(res.data.body.variable_list)
          console.log(guding)
          console.log(biandong)
          for (let i = 0; i < guding.length; i++) {
            this.$set(guding[i], 'jine', '')
          }
          for (let i = 0; i < biandong.length; i++) {
            this.$set(biandong[i], 'baifenbi', '')
            this.$set(biandong[i], 'jine', '')
          }
          this.guding_list = guding
          this.biandong_list = biandong
        } else if (res.data.code == 10214) {}
      })
    },
    // 自动计算
    // 固定金额
    zidong_guding_jine () {
      let jine = ''
      const reg = /\d(?=(?:\d{3})+\b)/g
      if (this.yierji == 1 && this.guding_list.length != 0) {
        for (let i = 0; i < this.guding_list.length; i++) {
          // jine=jine+Number(this.guding_list[i].jine)+''
          if (this.guding_list[i].jine != 0) {
            jine = Number(jine) + Number(this.guding_list[i].jine) + ''
          }
        }
        if (jine != '') {
          jine = jine.replace(reg, '$&,')
          this.modalb.guding_jine = jine
        } else {
          this.modalb.guding_jine = ''
        }
      }
    },
    zidong_biandong_jingfei_baifenbi_or_jine () {
      // if(this.modala.biandong_baifenbi.length!=0||this.modala.chengben_baifenbi.length!=0||this.modala.shuijin_baifenbi.length!=0){
      this.modala.biandong_jingfei_baifenbi = Number(this.modala.biandong_baifenbi) + Number(this.modala.chengben_baifenbi) + Number(this.modala.shuijin_baifenbi)
      // }
      let a = this.modalb.biandong_jine
      let b = this.modalb.chengben_jine
      let c = this.modalb.shuijin_jine
      var reg = /\d(?=(?:\d{3})+\b)/g
      if (!isNaN(Number(a))) {
        a = Number(a)
      } else {
        a = Number(a.replace(/,/g, ''))
      }
      if (!isNaN(Number(b))) {
        b = Number(b)
      } else {
        b = Number(b.replace(/,/g, ''))
      }
      if (!isNaN(Number(c))) {
        c = Number(c)
      } else {
        c = Number(c.replace(/,/g, ''))
      }
      this.modalb.biandong_jingfei_jine = (a + b + c).toFixed(2).replace(reg, '$&,')
    },
    // 变动经费百分比
    zidong_biandong_baifenbi () {
      let baifenbi = ''
      if (this.yierji == 1 && this.biandong_list.length != 0) {
        for (let i = 0; i < this.biandong_list.length; i++) {
          if (this.biandong_list[i].baifenbi != '') {
            baifenbi = Number(baifenbi) + Number(this.biandong_list[i].baifenbi) + ''
          }
        }
        if (baifenbi != '') {
          this.modala.biandong_baifenbi = baifenbi
        } else {
          this.modala.biandong_baifenbi = ''
        }
        this.zidong_biandong_kemu_jine()
      }
    },
    // 税金百分比   
    zidong_shuijin_baifenbi () {
      if (this.shuijin_list.length != 0 && this.yierji == 1) {
        let baifenbi = ''
        for (let i = 0; i < this.shuijin_list.length; i++) {
          if (this.shuijin_list[i].baifenbi != '') {
            baifenbi = Number(baifenbi) + Number(this.shuijin_list[i].baifenbi) + ''
          }
        }
        if (baifenbi != '') {
          this.modala.shuijin_baifenbi = baifenbi
        } else {
          this.modala.shuijin_baifenbi = ''
        }
      }
      // if(this.two_modala.shuifu_baifenbi!=''){
      //     let baifenbi=''
      //     if(this.shuijin_list.length!=0){
      //         for(let i=0;i<this.shuijin_list.length;i++){
      //             if(this.shuijin_list[i].baifenbi!=''){
      //                 baifenbi=Number(baifenbi)+(Number(this.two_modala.shuifu_baifenbi)*12)/100+Number(this.two_modala.shuifu_baifenbi)+Number(this.shuijin_list[i].baifenbi)+''
      //             }else{
      //                 baifenbi=(Number(this.two_modala.shuifu_baifenbi)*12)/100+Number(this.two_modala.shuifu_baifenbi)+''
      //             }
      //         }
      //     }else{
      //         baifenbi=(Number(this.two_modala.shuifu_baifenbi)*12)/100+Number(this.two_modala.shuifu_baifenbi)+''
      //     }
      //     if(baifenbi!=''){
      //         this.modala.shuijin_baifenbi=Number(baifenbi).toFixed(2)
      //     }else{
      //         this.modala.shuijin_baifenbi=''
      //     }
      //     // let baifenbi=(Number(this.two_modala.shuifu_baifenbi)*12)/100+Number(this.two_modala.shuifu_baifenbi)+''
      //     // this.modala.shuijin_baifenbi=baifenbi
      //     // this.zidong_pingheng_jine()
      // }
      // else{
      //     this.modala.shuijin_baifenbi=''
      // }
    },
    // 盈亏平衡金额
    zidong_pingheng_jine () {
      let jine = ''
      var reg = /\d(?=(?:\d{3})+\b)/g
      if (this.modala.shuijin_baifenbi != '' && this.modala.biandong_baifenbi != '' && this.modala.chengben_baifenbi != '' && this.modalb.guding_jine != '') {
        const bb1 = (1 - (Number(this.modala.chengben_baifenbi) / 100) - (Number(this.modala.biandong_baifenbi) / 100) - (Number(this.modala.shuijin_baifenbi) / 100))
        // console.log(isNaN(Number(this.modalb.guding_jine)))
        // console.log(this.modalb.guding_jine)
        if (!isNaN(Number(this.modalb.guding_jine))) {
          jine = Number(this.modalb.guding_jine) / Number(bb1)
        } else {
          jine = Number(this.modalb.guding_jine.replace(/,/g, '')) / Number(bb1)
        }
        jine = jine.toFixed(2).replace(reg, '$&,')
        console.log(Number(bb1))
        this.modalb.pingheng_jine = jine
        this.zidong_bianzhi_renshu_jine()
        this.zidong_maoli_baifenbi()
        this.zidong_bianjie_baifenbi()
      } else {
        this.modalb.cesuan_jine = ''
        this.modalb.pingheng_jine = ''
        this.modala.maoli_baifenbi = ''
      }
    },
    // 成本金额
    zidong_chengben_jine () {
      let jine = ''
      var reg = /\d(?=(?:\d{3})+\b)/g
      // jine=Number(this.modala.chengben_baifenbi)*Number(this.modalb.pingheng_jine.replace(/,/g,''))/100
      // this.modalb.chengben_jine=jine.toFixed(2).replace(reg,'$&,')
      console.log('老夫就要监听你2', this.chengben_list)
      if (this.yierji == 1 && this.chengben_list.length != 0) {
        for (let i = 0; i < this.chengben_list.length; i++) {
          if (this.chengben_list[i].jine != '') {
            if (!isNaN(Number(this.chengben_list[i].jine))) {
              jine = Number(jine) + Number(this.chengben_list[i].jine)
            } else {
              jine = Number(jine) + Number(this.chengben_list[i].jine.replace(/,/g, ''))
            }
          }
        }
        if (jine != '') {
          this.modalb.chengben_jine = jine.toFixed(2).replace(reg, '$&,')
          console.log(1111, this.modalb.chengben_jine)
        } else {
          this.modalb.chengben_jine = ''
        }
      } else {
        console.log('老夫就要监听你2', this.modalb)
        if (this.modala.chengben_baifenbi != '' && this.two_modalb.cesuanmubiao_jine != '') {
          jine = Number(this.modala.chengben_baifenbi) * Number(this.two_modalb.cesuanmubiao_jine) / 100
          this.modalb.chengben_jine = jine.toFixed(2).replace(reg, '$&,')
          console.log('老夫就要监听你', this.modalb)
        } else {
          this.modalb.chengben_jine = ''
        }
      }
    },
    // 成本百分比
    zidong_chengben_baifenbi () {
      let baifenbi = ''
      if (this.yierji == 1 && this.chengben_list.length != 0) {
        for (let i = 0; i < this.chengben_list.length; i++) {
          if (this.chengben_list[i].baifenbi != '') {
            baifenbi = Number(baifenbi) + Number(this.chengben_list[i].baifenbi) + ''
          }
        }
        if (baifenbi != '') {
          this.modala.chengben_baifenbi = baifenbi
        } else {
          this.modala.chengben_baifenbi = ''
        }
      }
    },
    // 成本科目金额
    zidong_chengben_kemu_jine () {
      var reg = /\d(?=(?:\d{3})+\b)/g
      if (this.yierji == 1 && this.chengben_list.length != 0) {
        for (let i = 0; i < this.chengben_list.length; i++) {
          if (this.two_modalb.cesuanmubiao_jine != '') {
            if (this.chengben_list[i].baifenbi != '') {
              this.chengben_list[i].jine = (Number(this.chengben_list[i].baifenbi) * Number(this.two_modalb.cesuanmubiao_jine) / 100).toFixed(2).replace(reg, '$&,')
            } else {
              this.chengben_list[i].jine = ''
            }
          }
        }
      }
    },
    // 税金科目金额
    zidong_shuijin_kemu_jine () {
      var reg = /\d(?=(?:\d{3})+\b)/g
      if (this.shuijin_list.length != 0 && this.two_modalb.cesuanmubiao_jine != '') {
        for (let i = 0; i < this.shuijin_list.length; i++) {
          if (this.shuijin_list[i].baifenbi != '') {
            this.shuijin_list[i].jine = (Number(this.two_modalb.cesuanmubiao_jine) * Number(this.shuijin_list[i].baifenbi) / 100).toFixed(2).replace(reg, '$&,')
          } else {
            this.shuijin_list[i].jine = ''
          }
        }
      }
    },
    // 税负金额
    // zidong_shuifu_jine(){
    //     let jine=''
    //     var reg =/\d(?=(?:\d{3})+\b)/g
    //     if(this.two_modalb.cesuanmubiao_jine!=''&&this.two_modala.shuifu_baifenbi!=''){
    //         jine=Number(this.two_modalb.cesuanmubiao_jine)*Number(this.two_modala.shuifu_baifenbi)/100
    //         this.two_modalb.shuifu_jine=jine.toFixed(2).replace(reg,'$&,')
    //         this.zidong_shuijin_fujia_jine()
    //     }else{
    //         this.two_modalb.shuifu_jine=''
    //     }
    // },
    // 税金及附加金额
    // zidong_shuijin_fujia_jine(){
    //     let jine=''
    //     var reg =/\d(?=(?:\d{3})+\b)/g
    //     jine=((Number(this.two_modalb.shuifu_jine.replace(/,/g,''))*12)/100).toFixed(2).replace(reg,'$&,')
    //     this.two_modalb.shuijin_jine=jine
    //     this.zidong_shuijin_jine()
    // },
    // 税金金额
    zidong_shuijin_jine () {
      var reg = /\d(?=(?:\d{3})+\b)/g
      let jine = ''
      if (this.yierji == 1 && this.shuijin_list.length != 0) {
        for (let i = 0; i < this.shuijin_list.length; i++) {
          if (this.shuijin_list[i].jine != '') {
            if (!isNaN(Number(this.shuijin_list[i].jine))) {
              jine = Number(jine) + Number(this.shuijin_list[i].jine)
            } else {
              jine = Number(jine) + Number(this.shuijin_list[i].jine.replace(/,/g, ''))
            }
            // Number(this.two_modalb.shuijin_jine.replace(/,/g,''))+Number(this.two_modalb.shuifu_jine.replace(/,/g,''))
          }
          // else{
          //     jine=Number(this.two_modalb.shuijin_jine.replace(/,/g,''))+Number(this.two_modalb.shuifu_jine.replace(/,/g,''))
          // }
        }
        // else{
        //     jine=Number(this.two_modalb.shuijin_jine.replace(/,/g,''))+Number(this.two_modalb.shuifu_jine.replace(/,/g,''))
        // }
        if (jine != '') {
          this.modalb.shuijin_jine = Number(jine).toFixed(2).replace(reg, '$&,')
        } else {
          this.modalb.shuijin_jine = ''
        }
        // jine=(Number(this.two_modalb.shuijin_jine.replace(/,/g,''))+Number(this.two_modalb.shuifu_jine.replace(/,/g,''))).toFixed(2).replace(reg,'$&,')
        // this.modalb.shuijin_jine=jine
      } else {
        if (this.two_modalb.cesuanmubiao_jine != '' && this.modala.shuijin_baifenbi != '') {
          jine = (Number(this.two_modalb.cesuanmubiao_jine) * Number(this.modala.shuijin_baifenbi) / 100).toFixed(2).replace(reg, '$&,')
          this.modalb.shuijin_jine = jine
        } else {
          this.modalb.shuijin_jine = ''
          this.modala.jingying_baifenbi = ''
          this.modalb.jingying_jine = ''
        }
      }
    },
    // 变动科目金额
    zidong_biandong_kemu_jine () {
      var reg = /\d(?=(?:\d{3})+\b)/g
      if (this.yierji == 1 && this.biandong_list.length != 0) {
        if (this.two_modalb.cesuanmubiao_jine != '') {
          for (let i = 0; i < this.biandong_list.length; i++) {
            if (this.biandong_list[i].baifenbi != '') {
              this.biandong_list[i].jine = (Number(this.biandong_list[i].baifenbi) * Number(this.two_modalb.cesuanmubiao_jine) / 100).toFixed(2).replace(reg, '$&,')
            } else {
              this.biandong_list[i].jine = ''
            }
            this.zidong_biandong_jingfei_jine()
          }
        }
      }
    },
    // 变动经费
    zidong_biandong_jingfei_jine () {
      var reg = /\d(?=(?:\d{3})+\b)/g
      let jine = ''
      if (this.yierji == 1 && this.biandong_list.length != 0) {
        for (let i = 0; i < this.biandong_list.length; i++) {
          if (this.biandong_list[i].jine != '') {
            jine = (Number(jine.replace(/,/g, '')) + Number(this.biandong_list[i].jine.replace(/,/g, ''))).toFixed(2).replace(reg, '$&,')
          }
        }
        if (jine != '') {
          this.modalb.biandong_jine = jine
          console.log(jine)
        } else {
          this.modalb.biandong_jine = ''
        }
      } else {
        if (this.two_modalb.cesuanmubiao_jine != '' && this.modala.biandong_baifenbi != '') {
          jine = (Number(this.modala.biandong_baifenbi) * Number(this.two_modalb.cesuanmubiao_jine) / 100).toFixed(2).replace(reg, '$&,')
          this.modalb.biandong_jine = jine
        } else {
          this.modalb.biandong_jine = ''
        }
      }
    },
    // 测算销售编制人数
    zidong_bianzhi_renshu_jine () {
      if (this.two_modalb.cesuanmubiao_jine != '' && this.modalb.renjunchanzhi_jine != '') {
        var reg = /\d(?=(?:\d{3})+\b)/g
        let jine = ''
        const b = Number(this.modalb.renjunchanzhi_jine)
        const a = Number(this.two_modalb.cesuanmubiao_jine)
        jine = (a / b).toFixed(0).replace(reg, '$&,')
        this.modalb.cesuan_jine = jine
      } else {
        this.modalb.cesuan_jine = ''
      }
    },
    // 本年目标增长率
    zidong_bennian_mubiao_zengzhang_baifenbi () {
      if (this.two_modalb.cesuanmubiao_jine != '' && this.two_modalb.shangnianxiaoshoue_jine != '') {
        let baifenbi = ''
        const reg = /\d(?=(?:\d{3})+\b)/g
        const a = Number(this.two_modalb.cesuanmubiao_jine)
        const b = Number(this.two_modalb.shangnianxiaoshoue_jine)
        baifenbi = (((a - b) / b) * 100).toFixed(0).replace(reg, '$&,')
        // this.modala.zengzhang_baifenbi=baifenbi
        this.$set(this.modala, 'zengzhang_baifenbi', baifenbi)
      }
    },
    // 本年目标销售增长额
    zidong_bennian_mubiao_xiaoshou_zhengzange () {
      if (this.two_modalb.shangnianxiaoshoue_jine != '' && this.two_modalb.cesuanmubiao_jine != '') {
        let baifenbi = ''
        const reg = /\d(?=(?:\d{3})+\b)/g
        baifenbi = (((Number(this.two_modalb.cesuanmubiao_jine) - Number(this.two_modalb.shangnianxiaoshoue_jine)) / Number(this.two_modalb.shangnianxiaoshoue_jine)) * 100).toFixed(2)
        console.log(baifenbi)
        if (baifenbi == 'Infinity') {
          baifenbi = '100.00'
        }
        // this.modala.bennian_xiaoshou_baifenbi=baifenbi
        this.$set(this.modala, 'bennian_xiaoshou_baifenbi', baifenbi)

        let jine = ''
        jine = (Number(this.two_modalb.cesuanmubiao_jine) - Number(this.two_modalb.shangnianxiaoshoue_jine)).toFixed(2)
        this.modalb.bennian_xiaoshou_jine = jine.replace(reg, '$&,')
        this.$set(this.modalb, 'bennian_xiaoshou_jine', jine.replace(reg, '$&,'))
      } else {
        // this.modala.bennian_xiaoshou_baifenbi=''
        this.$set(this.modala, 'bennian_xiaoshou_baifenbi', '')
        this.$set(this.modalb, 'bennian_xiaoshou_jine', '')
        // this.modalb.bennian_xiaoshou_jine=''
      }
    },
    // 毛利百分比+金额
    zidong_maoli_baifenbi () {
      if (this.modala.chengben_baifenbi != '' && this.modala.shuijin_baifenbi != '') {
        let baifenbi = ''
        const reg = /\d(?=(?:\d{3})+\b)/g
        baifenbi = (100 - Number(this.modala.chengben_baifenbi) - Number(this.modala.shuijin_baifenbi)).toFixed(2).replace(reg, '$&,')
        this.modala.maoli_baifenbi = baifenbi
        this.zidong_maoli_jine()
      }
    },
    // 毛利金额
    zidong_maoli_jine () {
      if (this.modala.maoli_baifenbi != '' && this.two_modalb.cesuanmubiao_jine != '') {
        let jine = ''
        jine = Number(this.two_modalb.cesuanmubiao_jine) * Number(this.modala.maoli_baifenbi) / 100
        const reg = /\d(?=(?:\d{3})+\b)/g
        this.modalb.maoli_jine = jine.toFixed(2).replace(reg, '$&,')
      } else {
        this.modalb.maoli_jine = ''
      }
    },
    // 边界利润百分比+金额
    zidong_bianjie_baifenbi () {
      if (this.modala.biandong_baifenbi != '' && this.modala.chengben_baifenbi != '' && this.modala.shuijin_baifenbi != '' && this.two_modalb.cesuanmubiao_jine != '') {
        const reg = /\d(?=(?:\d{3})+\b)/g
        let baifenbi = ''
        // if(this.chengben_list.length!=0){
        //     baifenbi=100-Number(this.modala.biandong_baifenbi.replace(/,/g,''))-Number(this.modala.chengben_baifenbi.replace(/,/g,''))-Number(this.modala.shuijin_baifenbi.replace(/,/g,''))
        // }else{
        //     baifenbi=100-Number(this.modala.biandong_baifenbi.replace(/,/g,''))-Number(this.modala.chengben_baifenbi)-Number(this.modala.shuijin_baifenbi.replace(/,/g,''))
        // }
        baifenbi = 100 - Number(this.modala.biandong_baifenbi) - Number(this.modala.chengben_baifenbi) - Number(this.modala.shuijin_baifenbi)
        this.modala.bianjie_baifenbi = baifenbi.toFixed(2) + ''
        this.modalb.bianjie_jine = (Number(this.two_modalb.cesuanmubiao_jine) * (Number(baifenbi) / 100)).toFixed(2).replace(reg, '$&,')
      } else {
        this.modala.bianjie_baifenbi = ''
        this.modalb.bianjie_jine = ''
      }
    },
    // 经营利润百分比+利润
    zidong_jingying_baifenbi () {
      console.log('执行了')
      if (this.modalb.biandong_jine != '' && this.modalb.chengben_jine != '' && this.modalb.shuijin_jine != '' && this.two_modalb.cesuanmubiao_jine != '' && this.modalb.guding_jine != '') {
        const reg = /\d(?=(?:\d{3})+\b)/g
        let baifenbi = ''
        let a = ''
        console.log('老夫进来了')
        let cesuanmubiao_jine = ''
        let biandong_jine = ''
        let chengben_jine = ''
        let shuijin_jine = ''
        let guding_jine = ''
        if (!isNaN(Number(this.two_modalb.cesuanmubiao_jine))) {
          cesuanmubiao_jine = Number(this.two_modalb.cesuanmubiao_jine)
        } else {
          cesuanmubiao_jine = Number(this.two_modalb.cesuanmubiao_jine.replace(/,/g, ''))
        }
        if (!isNaN(Number(this.modalb.biandong_jine))) {
          biandong_jine = Number(this.modalb.biandong_jine)
        } else {
          biandong_jine = Number(this.modalb.biandong_jine.replace(/,/g, ''))
        }
        if (!isNaN(Number(this.modalb.chengben_jine))) {
          chengben_jine = Number(this.modalb.chengben_jine)
        } else {
          chengben_jine = Number(this.modalb.chengben_jine.replace(/,/g, ''))
        }
        if (!isNaN(Number(this.modalb.shuijin_jine))) {
          shuijin_jine = Number(this.modalb.shuijin_jine)
        } else {
          shuijin_jine = Number(this.modalb.shuijin_jine.replace(/,/g, ''))
        }
        if (!isNaN(Number(this.modalb.guding_jine))) {
          guding_jine = Number(this.modalb.guding_jine)
        } else {
          guding_jine = Number(this.modalb.guding_jine.replace(/,/g, ''))
        }
        a = cesuanmubiao_jine - biandong_jine - chengben_jine - shuijin_jine - guding_jine
        const b = cesuanmubiao_jine
        console.log(1111, cesuanmubiao_jine)
        console.log(1111, biandong_jine)
        console.log(1111, chengben_jine)
        console.log(1111, shuijin_jine)
        console.log(1111, guding_jine)
        console.log(2222, b)
        baifenbi = (a / b * 100).toFixed(2).replace(reg, '$&,')
        this.modala.jingying_baifenbi = baifenbi
        console.log('老夫更改你了', a)
        this.modalb.jingying_jine = a.toFixed(2).replace(reg, '$&,')
      } else {
        this.modala.jingying_baifenbi = ''
        this.modalb.jingying_jine = ''
      }
    },
    // 监听所有的input
    zong_guding_jine () { // 总固定经费金额

    },
    zong_biandong_baifenbi () { // 总变动经费百分比
      if (this.modala.biandong_baifenbi == '') {
        this.modala.biandong_baifenbi = ''
      } else if (this.modala.biandong_baifenbi <= 0) {
        this.modala.biandong_baifenbi = 1
      } else if (this.modala.biandong_baifenbi > 100) {
        this.modala.biandong_baifenbi = 100
      }
    },
    zong_shuijin_baifenbi () { // 总税金百分比
      if (this.modala.shuijin_baifenbi == '') {
        this.modala.shuijin_baifenbi = ''
      } else if (this.modala.shuijin_baifenbi <= 0) {
        this.modala.shuijin_baifenbi = 1
      } else if (this.modala.shuijin_baifenbi > 100) {
        this.modala.shuijin_baifenbi = 100
      }
    },
    guding_bianji () {
      // this.zidong_guding_jine()
    },
    biandong_bianji (index) {
      if (this.biandong_list[index].baifenbi == '') {
        this.biandong_list[index].baifenbi = ''
      } else if (this.biandong_list[index].baifenbi <= 0) {
        this.biandong_list[index].baifenbi = 1
      } else if (this.biandong_list[index].baifenbi > 100) {
        this.biandong_list[index].baifenbi = 100
      }
      // this.zidong_biandong_baifenbi()
    },
    chengben_bianji (index) {
      if (this.chengben_list[index].baifenbi == '') {
        this.chengben_list[index].baifenbi = ''
      } else if (this.chengben_list[index].baifenbi <= 0) {
        this.chengben_list[index].baifenbi = 1
      } else if (this.chengben_list[index].baifenbi > 100) {
        this.chengben_list[index].baifenbi = 100
      }
      // this.zidong_biandong_baifenbi()
    },
    shuijin_bianji (i) {
      if (i.baifenbi == '') {
        i.baifenbi = ''
      } else if (i.baifenbi <= 0) {
        i.baifenbi = 1
      } else if (i.baifenbi > 100) {
        i.baifenbi = 100
      }
      // this.zidong_biandong_baifenbi()
    },
    key2 () { // 监听成本input百分比
      console.log(this.modalb)
      if (this.modala.chengben_baifenbi == '') {
        this.modala.chengben_baifenbi = ''
      } else if (this.modala.chengben_baifenbi <= 0) {
        this.modala.chengben_baifenbi = 1
      } else if (this.modala.chengben_baifenbi > 100) {
        this.modala.chengben_baifenbi = 100
      }
      // this.zidong_pingheng_jine()
      // this.zidong_shuijin_baifenbi()
      // this.zidong_biandong_baifenbi()
    },
    key4 () {
      // if(this.two_modala.shuifu_baifenbi==''){
      //     this.two_modala.shuifu_baifenbi==''
      // }else if(this.two_modala.shuifu_baifenbi<=0){
      //     this.two_modala.shuifu_baifenbi=1
      // }else if(this.two_modala.shuifu_baifenbi>100){
      //     this.two_modala.shuifu_baifenbi=100
      // }
      // this.zidong_shuijin_baifenbi()
    },
    key7 () { // 客单价金额
        
    },
    key9 () { // 人均产值
      // this.zidong_pingheng_jine()
      // this.zidong_bianzhi_renshu_jine()
    },
    key15 () { // 上年销售额
      // this.zidong_bennian_mubiao_zengzhang_baifenbi()
    },
    key17 () { // 测算目标销售额
      // this.zidong_bennian_mubiao_zengzhang_baifenbi()
      // this.zidong_maoli_jine()
      // this.zidong_bianjie_baifenbi()
      // this.zidong_jingying_baifenbi()
    },
    // 选择年份点击确定
    xuanze_nianfen_queding () {
      if (this.nian_sj != '') {
        const sj = this.nian_sj.getFullYear() + '年'
        if (this.shujuku_cunzai_shijian == '' && this.cunzai_shijian_list == '') {
          this.nianfen_xianshi_yincang = false
          this.time = sj
          this.cunzai_shijian_list.push({
            time: sj,
            yierji: '0',
            diyiwei: '1',
            modala: {
              guding_baifenbi: '',
              biandong_baifenbi: '',
              chengben_baifenbi: '',
              shuijin_baifenbi: '',
              kedanjia_baifenbi: '',
              renjunchanzhi_baifenbi: '',
              guding_baifenbi: '',
              cesuan_baifenbi: '',
              pingheng_baifenbi: '',
              zengzhang_baifenbi: '',
              maoli_baifenbi: '',
              bianjie_baifenbi: '',
              jingying_baifenbi: '',
              bennian_xiaoshou_baifenbi: ''
            },
            modalb: {
              guding_jine: '',
              biandong_jine: '',
              chengben_jine: '',
              shuijin_jine: '',
              kedanjia_jine: '',
              renjunchanzhi_jine: '',
              cesuan_jine: '',
              pingheng_jine: '',
              zengzhang_jine: '',
              maoli_jine: '',
              bianjie_jine: '',
              jingying_jine: '',
              bennian_xiaoshou_jine: ''
            },
            two_modala: {
              shuifu_baifenbi: '',
              shuijin_baifenbi: '',
              cesuanmubiao_baifenbi: ''
            },
            two_modalb: {
              shuifu_jine: '',
              shuijin_jine: '',
              shangnianxiaoshoue_jine: '',
              cesuanmubiao_jine: ''
            }
          })
          this.nian_sj = ''
          // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
          localStorage.setItem('biaoyi_shijian', JSON.stringify(this.cunzai_shijian_list))
        } else {
          if (this.shujuku_cunzai_shijian != '') {
            let a = ''
            for (let i = 0; i < this.shujuku_cunzai_shijian.length; i++) {
              console.log(this.shujuku_cunzai_shijian[i])
              if (this.shujuku_cunzai_shijian[i] == sj.slice(0, 4)) {
                a = '2'
                this.$message({
                  message: '已存在当前年份',
                  type: 'warning'
                })
              }
            }
            if (a != 2) {
              this.nianfen_xianshi_yincang = false
              this.time = sj
              this.modala.biandong_baifenbi = ''
              this.modala.chengben_baifenbi = ''
              this.modala.shuijin_baifenbi = ''
              this.modala.cesuan_baifenbi = ''
              this.modala.zengzhang_baifenbi = ''
              this.modala.maoli_baifenbi = ''
              this.modala.bianjie_baifenbi = ''
              this.modala.jingying_baifenbi = ''
              this.modala.bennian_xiaoshou_baifenbi = ''

              this.modalb.guding_jine = ''
              this.modalb.biandong_jine = ''
              this.modalb.chengben_jine = ''
              this.modalb.shuijin_jine = ''
              this.modalb.kedanjia_jine = ''
              this.modalb.renjunchanzhi_jine = ''
              this.modalb.cesuan_jine = ''
              this.modalb.pingheng_jine = ''
              this.modalb.zengzhang_jine = ''
              this.modalb.maoli_jine = ''
              this.modalb.bianjie_jine = ''
              this.modalb.jingying_jine = ''
              this.modalb.bennian_xiaoshou_jine = ''

              this.two_modala.cesuanmubiao_baifenbi = ''

              this.two_modalb.shangnianxiaoshoue_jine = ''
              this.two_modalb.cesuanmubiao_jine = ''
              this.diyiwei = '1'
              this.yierji = '0'
              this.cunzai_shijian_list.push({
                time: sj,
                yierji: '0',
                modala: {
                  biandong_baifenbi: '',
                  chengben_baifenbi: '',
                  shuijin_baifenbi: '',
                  cesuan_baifenbi: '',
                  zengzhang_baifenbi: '',
                  maoli_baifenbi: '',
                  bianjie_baifenbi: '',
                  jingying_baifenbi: '',
                  bennian_xiaoshou_baifenbi: ''
                },
                modalb: {
                  guding_jine: '',
                  biandong_jine: '',
                  chengben_jine: '',
                  shuijin_jine: '',
                  kedanjia_jine: '',
                  renjunchanzhi_jine: '',
                  cesuan_jine: '',
                  pingheng_jine: '',
                  zengzhang_jine: '',
                  maoli_jine: '',
                  bianjie_jine: '',
                  jingying_jine: '',
                  bennian_xiaoshou_jine: ''
                },
                two_modala: {
                  // shuifu_baifenbi:'',
                  // shuijin_baifenbi:'',
                  cesuanmubiao_baifenbi: ''
                },
                two_modalb: {
                  shuifu_jine: '',
                  shuijin_jine: '',
                  shangnianxiaoshoue_jine: '',
                  cesuanmubiao_jine: ''
                },
                diyiwei: '1'
              })
              this.nian_sj = ''
              // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
              localStorage.setItem('biaoyi_shijian', JSON.stringify(this.cunzai_shijian_list))
            }
          } else {
            if (this.cunzai_shijian_list != '') {
              let a = ''
              for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                if (this.cunzai_shijian_list[i].time == sj) {
                  this.$message({
                    message: '已存在当前年份',
                    type: 'warning'
                  })
                  a = '2'
                }
              }
              if (a != '2') {
                a = '1'
                this.nianfen_xianshi_yincang = false
                this.time = sj
                this.cunzai_shijian_list.push({
                  time: sj,
                  yierji: '0',
                  modala: {
                    guding_baifenbi: '',
                    biandong_baifenbi: '',
                    chengben_baifenbi: '',
                    shuijin_baifenbi: '',
                    kedanjia_baifenbi: '',
                    renjunchanzhi_baifenbi: '',
                    guding_baifenbi: '',
                    cesuan_baifenbi: '',
                    pingheng_baifenbi: '',
                    zengzhang_baifenbi: '',
                    maoli_baifenbi: '',
                    bianjie_baifenbi: '',
                    jingying_baifenbi: '',
                    bennian_xiaoshou_baifenbi: ''
                  },
                  modalb: {
                    guding_jine: '',
                    biandong_jine: '',
                    chengben_jine: '',
                    shuijin_jine: '',
                    kedanjia_jine: '',
                    renjunchanzhi_jine: '',
                    cesuan_jine: '',
                    pingheng_jine: '',
                    zengzhang_jine: '',
                    maoli_jine: '',
                    bianjie_jine: '',
                    jingying_jine: '',
                    bennian_xiaoshou_jine: ''
                  },
                  two_modala: {
                    shuifu_baifenbi: '',
                    shuijin_baifenbi: '',
                    cesuanmubiao_baifenbi: ''
                  },
                  two_modalb: {
                    shuifu_jine: '',
                    shuijin_jine: '',
                    shangnianxiaoshoue_jine: '',
                    cesuanmubiao_jine: ''
                  },
                  diyiwei: '1'
                })
                this.nian_sj = ''
                // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
                localStorage.setItem('biaoyi_shijian', JSON.stringify(this.cunzai_shijian_list))
                // this.chaxun()
              }
            }
          }
        }
      } else {
        this.$message({
          message: '请选择年份',
          type: 'warning'
        })
      }
    },
    // 选择年份点击取消
    xuanze_nianfen_quxiao () {
      if (this.shujuku_cunzai_shijian != '' || this.cunzai_shijian_list != '') {
        this.nianfen_xianshi_yincang = false
      } else {
        this.$router.push('/aspreackeven')
        // sessionStorage.setItem("pc_mlbb_mubiao_biao",'-1');
      }
    },

    // 打开固定的科目
    dianji_xinzeng (i, name) {
      this.xuanze_xinzeng = true
      this.xinzeng_tit = name
      if (i == 2) {
        console.log(this.guding_list)
        if (this.guding_list.length != 0) {
          this.xuanzhong_gouxuan = this.guding_list.map(item => item.subject_code)
        } else {
          this.xuanzhong_gouxuan = []
        }
      } else if (i == 3001) {
        if (this.chengben_list.length != 0) {
          this.xuanzhong_gouxuan = this.chengben_list.map(item => item.subject_code)
        } else {
          this.xuanzhong_gouxuan = []
        }
      } else if (i == 3002) {
        if (this.biandong_list.length != 0) {
          this.xuanzhong_gouxuan = this.biandong_list.map(item => item.subject_code)
        } else {
          this.xuanzhong_gouxuan = []
        }
      }
      query_ent_subject_list_tree({
        data: {
          ent_id: this.$ent_id(),
          subject_prop: i
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10241) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.xinzeng_list = date
          // [0].children
          // for(let i=0;i<date.length;i++){
          //     if(date[i].subject_prop==1){
          //     date[i]['shuxing']='收入'
          //     }else if(date[i].subject_prop==4){
          //     date[i]['shuxing']='固定经费'
          //     }else if(date[i].subject_prop==5){
          //     date[i]['shuxing']='变动经费'
          //     }else if(date[i].subject_prop==2){
          //     date[i]['shuxing']='成本'
          //     }else if(date[i].subject_prop==3){
          //     date[i]['shuxing']='税金'
          //     }
          // }
          // this.hesuan_list=date
        } else if (res.data.code == 10242) {}
      })
    },
    // 删除固定科目
    shanchu_guding (index) {
      this.guding_list.splice(index, 1)
    },
    // 删除变动科目
    shanchu_biandong (index) {
      this.biandong_list.splice(index, 1)
    },
    // 删除成本科目
    shanchu_chengben (index) {
      this.chengben_list.splice(index, 1)
    },
    // 删除税金科目
    shanchu_shuijin (index) {
      this.shuijin_list.splice(index, 1)
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao",'-1');
    },
    // 判断本表
    panduan_baocun_biao () {
      // insert_as_breakeven
      if (this.modalb.guding_jine != '' && this.modala.biandong_baifenbi != '' && this.modalb.biandong_jine != '' && this.modala.chengben_baifenbi != '' && this.modalb.chengben_jine != '' && this.modala.shuijin_baifenbi != '' && this.modalb.shuijin_jine != '' && this.modalb.pingheng_jine != '' && this.modala.bennian_xiaoshou_baifenbi != '' && this.modalb.bennian_xiaoshou_jine != '' && this.two_modalb.shangnianxiaoshoue_jine != '' && this.two_modalb.bennian_xiaoshou_jine != '' && this.two_modalb.kedanjia_jine != '' && this.two_modalb.renjunchanzhi_jine != '' && this.modala.maoli_baifenbi != '' && this.modalb.maoli_jine != '' && this.bianjie_baifenbi != '' && this.biandong_jine != '' && this.modala.jingying_baifenbi != '' && this.modalb.jingying_jine != '') {
        this.baocun_liang_or_no = true
      } else {
        this.baocun_liang_or_no = false
      }
    },
    // 保存本表
    baocun_biao () {
      // insert_as_breakeven
      console.log(this.time.slice(0, 4))
      if (this.modalb.guding_jine != '' && this.modala.biandong_baifenbi != '' && this.modalb.biandong_jine != '' && this.modala.chengben_baifenbi != '' && this.modalb.chengben_jine != '' && this.modala.shuijin_baifenbi != '' && this.modalb.shuijin_jine != '' && this.modalb.pingheng_jine != '' && this.modala.bennian_xiaoshou_baifenbi != '' && this.modalb.bennian_xiaoshou_jine != '' && this.two_modalb.shangnianxiaoshoue_jine != '' && this.two_modalb.bennian_xiaoshou_jine != '' && this.two_modalb.kedanjia_jine != '' && this.two_modalb.renjunchanzhi_jine != '' && this.modala.maoli_baifenbi != '' && this.modalb.maoli_jine != '' && this.bianjie_baifenbi != '' && this.biandong_jine != '' && this.modala.jingying_baifenbi != '' && this.modalb.jingying_jine != '') {
        let guding_jine = ''
        if (!isNaN(Number(this.modalb.guding_jine))) {
          guding_jine = this.modalb.guding_jine
        } else {
          guding_jine = this.modalb.guding_jine.replace(/,/g, '')
        }
        let biandong_jine = ''
        if (!isNaN(Number(this.modalb.biandong_jine))) {
          biandong_jine = this.modalb.biandong_jine
        } else {
          biandong_jine = this.modalb.biandong_jine.replace(/,/g, '')
        }
        let chengben_jine = ''
        if (!isNaN(Number(this.modalb.chengben_jine))) {
          chengben_jine = this.modalb.chengben_jine
        } else {
          chengben_jine = this.modalb.chengben_jine.replace(/,/g, '')
        }
        let shuijin_jine = ''
        if (!isNaN(Number(this.modalb.shuijin_jine))) {
          shuijin_jine = this.modalb.shuijin_jine
        } else {
          shuijin_jine = this.modalb.shuijin_jine.replace(/,/g, '')
        }
        let pinghengdian_jine = ''
        if (!isNaN(Number(this.modalb.pingheng_jine))) {
          pinghengdian_jine = this.modalb.pingheng_jine
        } else {
          pinghengdian_jine = this.modalb.pingheng_jine.replace(/,/g, '')
        }
        let bennian_zengzhang_jine = ''
        if (!isNaN(Number(this.modalb.bennian_xiaoshou_jine))) {
          bennian_zengzhang_jine = this.modalb.bennian_xiaoshou_jine
        } else {
          bennian_zengzhang_jine = this.modalb.bennian_xiaoshou_jine.replace(/,/g, '')
        }
        let renshu_jine = ''
        if (!isNaN(Number(this.modalb.cesuan_jine))) {
          renshu_jine = this.modalb.cesuan_jine
        } else {
          renshu_jine = this.modalb.cesuan_jine.replace(/,/g, '')
        }
        let maoli_jine = ''
        if (!isNaN(Number(this.modalb.maoli_jine))) {
          maoli_jine = this.modalb.maoli_jine
        } else {
          maoli_jine = this.modalb.maoli_jine.replace(/,/g, '')
        }
        let bianjie_jine = ''
        if (!isNaN(Number(this.modalb.bianjie_jine))) {
          bianjie_jine = this.modalb.bianjie_jine
        } else {
          bianjie_jine = this.modalb.bianjie_jine.replace(/,/g, '')
        }
        let jingying_jine = ''
        if (!isNaN(Number(this.modalb.jingying_jine))) {
          jingying_jine = this.modalb.jingying_jine
        } else {
          jingying_jine = this.modalb.jingying_jine.replace(/,/g, '')
        }
        let guding_lista = []
        if (this.guding_list.length != 0) {
          for (let i = 0; i < this.guding_list.length; i++) {
            if (this.guding_list[i].jine != '') {
              guding_lista.push({
                label: this.guding_list[i].label,
                subject_code: this.guding_list[i].subject_code,
                amt: this.guding_list[i].jine + ''
              })
              console.log(guding_lista)
            }
          }
        }
        if (guding_lista.length == 0) {
          guding_lista = null
        }
        let biandong_list = []
        if (this.biandong_list.length != 0) {
          for (let i = 0; i < this.biandong_list.length; i++) {
            if (this.biandong_list[i].jine != '' && this.biandong_list[i].baifenbi != '') {
              biandong_list.push({
                label: this.biandong_list[i].label,
                subject_code: this.biandong_list[i].subject_code,
                amt: this.biandong_list[i].jine + '',
                rate: this.biandong_list[i].baifenbi + ''
              })
            }
          }
          if (biandong_list.length == 0) {
            biandong_list = null
          }
        }
        let chengben_list = []
        if (this.chengben_list.length != 0) {
          for (let i = 0; i < this.chengben_list.length; i++) {
            if (this.chengben_list[i].jine != '' && this.chengben_list[i].baifenbi != '') {
              chengben_list.push({
                label: this.chengben_list[i].label,
                subject_code: this.chengben_list[i].subject_code,
                amt: this.chengben_list[i].jine + '',
                rate: this.chengben_list[i].baifenbi + ''
              })
            }
          }
        }
        if (chengben_list.length == 0) {
          chengben_list = null
        }
        let shuijin_list = []
        if (this.shuijin_list.length != 0) {
          for (let i = 0; i < this.shuijin_list.length; i++) {
            if (this.shuijin_list[i].jine != '' && this.shuijin_list[i].baifenbi != '') {
              shuijin_list.push({
                label: this.shuijin_list[i].label,
                subject_code: this.shuijin_list[i].subject_code,
                amt: this.shuijin_list[i].jine + '',
                rate: this.shuijin_list[i].baifenbi + ''
              })
            }
          }
        }
        if (shuijin_list.length == 0) {
          shuijin_list = null
        }
        let kedanjia_jine = this.modalb.kedanjia_jine + ''
        let renjunchanzhi_jine = this.modalb.renjunchanzhi_jine + ''
        let shangnianxiaoshoue_jine = this.two_modalb.shangnianxiaoshoue_jine + ''
        let cesuanmubiao_jine = this.two_modalb.cesuanmubiao_jine + ''
        if (!this.danwei) {
          if (guding_lista != null) {
            for (let i = 0; i < guding_lista.length; i++) {
              if (!isNaN(Number(guding_lista[i].amt))) {
                guding_lista[i].amt = Number(guding_lista[i].amt) + ''
              } else {
                guding_lista[i].amt = Number(guding_lista[i].amt.replace(/,/g, '')) + ''
              }
            }
          }
          if (biandong_list != null) {
            for (let i = 0; i < biandong_list.length; i++) {
              if (!isNaN(Number(biandong_list[i].amt))) {
                biandong_list[i].amt = Number(biandong_list[i].amt) + ''
              } else {
                biandong_list[i].amt = Number(biandong_list[i].amt.replace(/,/g, '')) + ''
              }
            }
          }
          if (chengben_list != null) {
            for (let i = 0; i < chengben_list.length; i++) {
              if (!isNaN(Number(chengben_list[i].amt))) {
                chengben_list[i].amt = Number(chengben_list[i].amt) + ''
              } else {
                chengben_list[i].amt = Number(chengben_list[i].amt.replace(/,/g, '')) + ''
              }
            }
          }
          if (shuijin_list != null) {
            for (let i = 0; i < shuijin_list.length; i++) {
              if (!isNaN(Number(shuijin_list[i].amt))) {
                shuijin_list[i].amt = Number(shuijin_list[i].amt) + ''
              } else {
                shuijin_list[i].amt = Number(shuijin_list[i].amt.replace(/,/g, '')) + ''
              }
            }
          }
        } else {
          guding_jine = Number(guding_jine) * 10000 + ''
          biandong_jine = Number(biandong_jine) * 10000 + ''
          chengben_jine = Number(chengben_jine) * 10000 + ''
          shuijin_jine = Number(shuijin_jine) * 10000 + ''
          kedanjia_jine = Number(kedanjia_jine) * 10000 + ''
          renjunchanzhi_jine = Number(renjunchanzhi_jine) * 10000 + ''
          renshu_jine = Number(renshu_jine) * 10000 + ''
          pinghengdian_jine = Number(pinghengdian_jine) * 10000 + ''
          bennian_zengzhang_jine = Number(bennian_zengzhang_jine) * 10000 + ''
          shangnianxiaoshoue_jine = Number(shangnianxiaoshoue_jine) * 10000 + ''
          cesuanmubiao_jine = Number(cesuanmubiao_jine) * 10000 + ''
          maoli_jine = Number(maoli_jine) * 10000 + ''
          bianjie_jine = Number(bianjie_jine) * 10000 + ''
          jingying_jine = Number(jingying_jine) * 10000 + ''
          if (guding_lista != null) {
            for (let i = 0; i < guding_lista.length; i++) {
              if (!isNaN(Number(guding_lista[i].amt))) {
                guding_lista[i].amt = Number(guding_lista[i].amt) * 10000 + ''
              } else {
                guding_lista[i].amt = Number(guding_lista[i].amt.replace(/,/g, '')) * 10000 + ''
              }
            }
          }
          if (biandong_list != null) {
            for (let i = 0; i < biandong_list.length; i++) {
              if (!isNaN(Number(biandong_list[i].amt))) {
                biandong_list[i].amt = Number(biandong_list[i].amt) * 10000 + ''
              } else {
                biandong_list[i].amt = Number(biandong_list[i].amt.replace(/,/g, '')) * 10000 + ''
              }
            }
          }
          if (chengben_list != null) {
            for (let i = 0; i < chengben_list.length; i++) {
              if (!isNaN(Number(chengben_list[i].amt))) {
                chengben_list[i].amt = Number(chengben_list[i].amt) * 10000 + ''
              } else {
                chengben_list[i].amt = Number(chengben_list[i].amt.replace(/,/g, '')) * 10000 + ''
              }
            }
          }
          if (shuijin_list != null) {
            for (let i = 0; i < shuijin_list.length; i++) {
              if (!isNaN(Number(shuijin_list[i].amt))) {
                shuijin_list[i].amt = Number(shuijin_list[i].amt) * 10000 + ''
              } else {
                shuijin_list[i].amt = Number(shuijin_list[i].amt.replace(/,/g, '')) * 10000 + ''
              }
            }
          }
        }
        insert_as_breakeven({
          data: {
            ent_id: this.$ent_id(),
            year: this.time.slice(0, 4),
            fixed_funds: guding_jine + '',
            variable_funds: biandong_jine,
            cost: chengben_jine,
            amt_tax: shuijin_jine,
            pct: kedanjia_jine,
            per_output_value: renjunchanzhi_jine,
            staff_perpared_est: renshu_jine,
            breakeven_point: pinghengdian_jine,
            rate_growth_aim_est: this.modala.bennian_xiaoshou_baifenbi,
            amt_growth_aim_est: bennian_zengzhang_jine,
            amt_sale_ly: shangnianxiaoshoue_jine,
            amt_sale_aim_est: cesuanmubiao_jine,
            profit_gross: maoli_jine,
            profit_margin: bianjie_jine,
            profit: jingying_jine,
            variable_funds_rate: this.modala.biandong_baifenbi + '',
            cost_rate: this.modala.chengben_baifenbi + '',
            amt_tax_rate: this.modala.shuijin_baifenbi + '',
            // vat_burden_rate:   //增值税税负
            // tax_surcharges_rate:  //税金及附加
            profit_gross_rate: this.modala.maoli_baifenbi,
            profit_margin_rate: this.modala.bianjie_baifenbi,
            profit_rate: this.modala.jingying_baifenbi,
            user_id: this.$jichuxinxi().user_id,
            fixed_list: guding_lista,
            variable_list: biandong_list,
            cost_list: chengben_list,
            tax_list: shuijin_list
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10225) {
            this.$message({
              message: '保存成功',
              type: 'success'
            }) 
            this.chutu = true
          } else if (res.data.code == 10226) {}
        })
      } else {
        this.$message({
          message: '请填写完整的信息,再保存',
          type: 'warning'
        }) 
      }
    },
    // 选择新增确定
    xuanze_xinzeng_queding () {
      const date = this.$refs.tree.getCheckedNodes()
      if (date.length != 0) {
        this.xuanze_xinzeng_list = []
        for (let i = 0; i < date.length; i++) {
          if (date[i].children == undefined) {
            console.log(date[i])
            this.$set(date[i], 'jine', '')
            this.$set(date[i], 'baifenbi', '')
            this.xuanze_xinzeng_list.push(date[i])
          }
        }
        if (this.xinzeng_tit == '固定经费科目') {
          this.guding_list = this.xuanze_xinzeng_list
          this.xuanze_xinzeng = false
        } else if (this.xinzeng_tit == '变动费用科目') {
          this.biandong_list = this.xuanze_xinzeng_list
          this.xuanze_xinzeng = false
        } else if (this.xinzeng_tit == '成本科目') {
          this.chengben_list = this.xuanze_xinzeng_list
          this.xuanze_xinzeng = false
        } else if (this.xinzeng_tit == '税金科目') {
          this.biandong_list = this.xuanze_xinzeng_list
          this.xuanze_xinzeng = false
        }
      } else {
        this.$message({
          message: '请选择要新增的科目',
          type: 'warning'
        })
      }
    },
    // 滑块选择
    danwei_xuanze () {
      console.log(this.danwei)
    },
    // 错误文字多会出现
    cuowu () {
      if (this.modalb.pingheng_jine != '' && this.two_modalb.cesuanmubiao_jine != '') {
        let cesuanmubiao_jine = ''
        if (!isNaN(Number(this.two_modalb.cesuanmubiao_jine))) {
          cesuanmubiao_jine = Number(this.two_modalb.cesuanmubiao_jine)
        } else {
          cesuanmubiao_jine = Number(this.two_modalb.cesuanmubiao_jine.replace(/,/g, ''))
        }
        let pingheng_jine = ''
        if (!isNaN(Number(this.modalb.pingheng_jine))) {
          pingheng_jine = Number(this.modalb.pingheng_jine)
        } else {
          pingheng_jine = Number(this.modalb.pingheng_jine.replace(/,/g, ''))
        }
        if (pingheng_jine >= cesuanmubiao_jine) {
          this.cuowu_text = true
        } else {
          this.cuowu_text = false
        }
      }
    },
    jin_chutu () {
      if (this.chutu) {
        // this.chuxian='111'
        // this.quan=this.time
        // console.log(this.chuanshu)

        this.$refs.mubiao_tu1.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '请先进行保存',
          type: 'warning'
        }) 
      }
    },

    // 查询是否可以点击出图
    chaxun_shifou_chutu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const data = JSON.parse(res.data.body.data)
          console.log(data)
          console.log(this.time)
          if (data.length != 0) {
            // if(data.map(item=>{if(this.time.slice(0,4)==item){return true}})){
            //     this.chutu=true
            // }
            for (let a = 0; a < data.length; a++) {
              if (data[a] == this.time.slice(0, 4)) {
                this.chutu = true
                break
              }
            }
          } else {
            this.chutu = false
          }
        }
      })
    } 
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tableone {
    height:100%;
    padding: 0 0.31rem 0.34rem 0.31rem;
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #9a86db;
                        border:0.01rem solid #9a86db;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
    .title{
        font-size: 0.22rem;
        color:#1A2533;
        text-align: center;
        margin:0 auto;
        padding-top:0.43rem;
    }
    .tit{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.27rem 0 0.21rem 0;
        .tit_p1{
            font-size: 0.14rem;
            color:#6F6D6F;
            display: flex;
            align-items: center;
            span{
                display: block;
                background:#9a86db;
                border-radius: 0.02rem;
                font-size: 0.18rem;
                color:#FFFFFF;
                text-align: center;
                line-height: 0.31rem;
                height: 0.31rem;
                width:0.8rem;
                margin-right:0.43rem;
                cursor: pointer;
            }
        }
        .tit_div1{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left:-1.77rem;
            p{
                display: flex;
                align-items: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                margin-right: 0.27rem;
                cursor: pointer;
                img{
                    height:0.19rem;
                    width: 0.19rem;
                    margin-right:0.12rem;
                }
            }
        }
        .danwei{
            font-size: 0.12rem;
        }
    }
    .laoye-table-head{
        display: block;
        height:0.68rem;
        display: flex;
        justify-content: flex-end;
        padding: 0 0.17rem;
        border:0.01rem solid #888;
        border-bottom:none;
        align-items: center;
        background: #fff;
        .p1{
            padding: 0 0.21rem;
            height:0.31rem;
            line-height: 0.31rem;
            border:0.01rem solid #979797;
            background: #fff;
            font-size: 0.18rem;
            color: #444444;
            border-radius: 0.03rem;
            cursor: pointer;
        }
        .p2{
            display: flex;
            align-items: center;
            span{
                display: block;
                padding: 0 0.21rem;
                height:0.31rem;
                line-height: 0.31rem;
                border:0.01rem solid #979797;
                background: #fff;
                margin-left:0.16rem;
                font-size: 0.18rem;
                color: #444444;
                border-radius: 0.03rem;
                cursor: pointer;
            }
        }
    }
    .laoye-tbody{
        border:0.01rem solid #888888;
        width:100%;
        border-collapse:collapse;
            tr{
                td{
                    height:0.49rem;
                    width:20%;
                    text-align: center;
                    line-height: 0.49rem !important;
                    font-size: 0.14rem;
                    color:#4C4A4D;
                    position: relative;
                    input{
                        height:0.49rem;
                        flex:1;
                        width:80%;
                        // width:100%;
                        border:none;
                        padding: 0 10%;
                        text-align: right;
                        &::-webkit-input-placeholder {
                            text-align: center;
                        }
                    }
                    .shan{
                        font-size: 0.12rem;
                        color:#9a86db;
                        cursor: pointer;
                    }
                    s{
                        position: absolute;
                        right:0.03rem;
                        line-height: 0.49rem;
                    }
                    .xuanfuzi{
                        position: absolute;
                        bottom: 0;
                        font-size: 0.12rem;
                        color:#E6A23C;
                        left:50%;
                        margin-left: -1.08rem;
                        line-height: 0.2rem;
                    }
                }
                .zidong{
                    background:#C8D1BB;
                    color:#FFFFFF;
                    font-size: 0.14rem;
                    text-align: right;
                    padding: 0 2%;
                }
                .caozuo{
                    width:40%;
                    height:40%;
                    display: flex;
                    justify-content:center;
                    margin: 0 auto;
                    align-items: center;
                    border:0.01rem solid #888888;
                    border-radius: 0.04rem;
                    cursor: pointer;
                    span{
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        margin-left:0.11rem
                    }
                }
                .wuyong{
                    background:#E0E3E7;
                }
            }
            .laoye-tit{
                td{
                    font-size: 0.18rem;
                    color:#1A2533;
                }
            }
            .er_ji{
                background: #F4F7FD;
            }
    }
    .xuanze_liebiao{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box{
            height:5.84rem;
            width:4.82rem;
            background:#fff;
            position: absolute;
            left:50%;
            margin-left: -2.41rem;
            top:50%;
            margin-top: -2.92rem;
            border-radius: 0.04rem;
            .xuan_tit{
                font-size: 0.22rem;
                color:#4C4A4D;
                text-align: center;
                padding-top: 0.24rem;
                margin-bottom: 0.3rem;
            }
            .box_xuanze{
                font-size: 0.14rem;
                color:#1A2533;
                margin-left:0.54rem;
                margin-bottom: 0.1rem;
            }
            .liebiao{
                height:3.54rem;
                width:3.85rem;
                background:#D8E6F7;
                margin: 0 auto;
                overflow:auto;
                // overflow-y: hidden;
                overflow-y: scroll;
                border-radius: 0.04rem;
                &::-webkit-scrollbar {
                    width: 0.19rem;
                    height: 0;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .custom-tree-node{
                    flex:1;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.14rem;
                    color:#4C4A4D;
                    img{
                        height:0.11rem;
                        width:0.2rem;
                    }
                }
            }
            .xuan_box_foot{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:0.38rem;
                span{
                    padding: 0.12rem 0.27rem;
                    font-size: 0.14rem;
                    color:#888888;
                    margin: 0 0.2rem;
                    border:0.01rem solid #888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #9a86db;
                        border:0.01rem solid #9a86db;
                        color:#fff;
                    }
                }
            }
        }
    }
    .daping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 90%;
            left:5%;
            top:5%;
            background: #F1F3F9;
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .box_nei{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                }
                .cesuan_biao{
                    // position: absolute;
                    padding-top:0.18rem;
                    margin-left: 0.16rem;
                    .left_ce{
                        display: flex;
                        align-items: center;
                        margin-bottom: 0.19rem;
                        position: relative;
                        p{
                            width:1.1rem;
                            text-align: right;
                            font-size: 0.14rem;
                            color:#4C4A4D;
                            margin-right: 0.19rem;
                            .span{
                                font-size: 0.14rem;
                                color:#9a86db;
                            }
                        }
                        .span2{
                            position: absolute;
                            left:2.55rem;
                            font-size: 0.14rem;
                        }
                        input{
                            height:0.3rem;
                            width:1.16rem;
                            padding:0 0.12rem;
                            border:0.01rem solid #888888;
                            border-radius: 0.04rem;
                        }
                    }
                    li{
                        div{
                            width:2.4rem;
                            height:0.35rem;
                            margin-left:0.17rem;
                            font-size: 0.14rem;
                            color:#fff;
                            text-align: center;
                            line-height: 0.35rem;
                            border-radius: 0.03rem;
                            background:#9a86db;
                        }
                    }
                }
                .tu_text{
                    position: absolute;
                    top:24%;
                    left: 18.5%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:55%;
                    p{
                        span{
                            &:nth-child(1){
                                font-size: 0.14rem;
                                color: #4C4A4D;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                                color: #4C4A4D;
                            }
                        }
                    }
                }
                .tu_text_ce{
                    position: absolute;
                    top:2rem;
                    left: 2.88rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width:55%;
                    p{
                        span{
                            &:nth-child(1){
                                font-size: 0.14rem;
                                color: #4C4A4D;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                                color: #4C4A4D;
                            }
                        }
                    }
                }
                #myCharts2{
                    position: absolute;
                    top:2rem;
                }
                .shili{
                    position: absolute;
                    right:2.17rem;
                    top:1.48rem;
                    li{
                        display: flex;
                        align-items: center;
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        height:0.67rem;
                        span{
                            display: block;
                            height:0.17rem;
                            width:0.17rem;
                            margin-right: 0.17rem;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .daping {
            width:1600px;
        }
    }
}
</style>
